<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4 lg4>
        <v-form ref="form" v-model="valid" @submit="login">
          <v-card tile class="mx-auto bglogin" flat max-width="480" rounded>
            <v-img contain height="20vh" :src="company.logo"></v-img>
            <v-card-text>
              <v-text-field
                light
                background-color="white"
                v-if="storelst.length === 0"
                v-model="credential.user_email"
                :rules="f_required"
                solo
                required
                prepend-inner-icon="mdi-account-outline"
                hide-details
              >
              </v-text-field>
              <v-text-field
                light
                prepend-inner-icon="mdi-lock-outline"
                background-color="white"
                class="mt-3 mb-3"
                v-if="storelst.length === 0"
                v-model="credential.user_password"
                type="password"
                :rules="f_required"
                solo
                required
                hide-details
              >
              </v-text-field>
              <v-select
                solo
                v-if="storelst.length > 0"
                v-model="credential.store"
                :items="storelst"
                label="Centro Productivo"
                item-text="name"
                item-value="store_id"
                height="50px"
                hide-details
              >
              </v-select>
              <v-card-actions v-if="storelst.length === 0">
                <v-checkbox v-model="accept_terms">
                  <template v-slot:label>
                    <div>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn text to="/about" @click.stop v-on="on" x-small>
                            Acepto los Terminos y Condiciones de uso
                          </v-btn>
                        </template>
                        Ver los terminos y condiciones de uso
                      </v-tooltip>
                    </div>
                  </template>
                </v-checkbox>
              </v-card-actions>

              <v-card-actions>
                <v-btn large block color="success" type="submit" @click="validate">
                  Continuar
                </v-btn>
              </v-card-actions>
            </v-card-text>
          </v-card>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { webserver } from "../../services/webserver.js";
import createProfile from "../../utils/createProfile.js";
import createStore from "../../utils/createStore.js";

export default {
  name: "Login",
  props: {
    nextUrl: {
      default: null,
    },
  },
  components: {},
  beforeMount() {
    this.company = this.$store.getters.company;
  },
  mounted() {
    this.$store.dispatch("setNavStatus", false);
    //this.$store.dispatch("set_menu", false);
  },
  data() {
    return {
      lhost: window.location.hostname,
      accept_terms: false,
      errors: [],
      valid: false,
      f_required: [(v) => !!v || "Requerido"],
      loading_status: false,
      credential: {
        store: null,
        user_email: null,
        user_password: "",
        token: null,
      },
      storelst: [],
      profile: createProfile(),
      company: createStore(),
    };
  },

  methods: {
    validate() {
      this.$refs.form.validate();
    },
    login(e) {
      e.preventDefault();
      this.$store.dispatch("setloading", true);
      var md5 = require("md5");
      var qry = {
        user_email: this.credential.user_email,
        user_password: md5(this.credential.user_password),
        user_token: this.credential.token,
        account: this.company.account,
        store: this.credential.store,
      };
      var metodo = "pre_login_b";
      if (this.credential.store) {
        metodo = "login";
      }
      webserver(metodo, qry, (data) => {
        this.$store.dispatch("setloading", false);
        if (metodo === "login") {
          console.log("login", data);
          this.profile.name = data.profile.user_name;
          this.profile.email = data.profile.user_email;
          this.profile.token = data.profile.token;
          this.profile.picture = data.profile.picture;
          this.profile.socialName = "WEB";
          this.profile.account = data.company.account;
          this.profile.code = data.profile.code;
          this.profile.user_access = data.profile.user_access;
          data.company.logo =
            "https://" +
            this.$store.getters.getHost +
            "/logos/" +
            data.company.account +
            "/" +
            data.company.code +
            "/logo.webp";

          this.$store.dispatch("setProfile", this.profile);
          this.$store.dispatch("setCompany", data.company);

          window.token = data.token;
          window.profile = this.profile;
          window.company = data.company;

          const encryptedText = this.CryptoJS.AES.encrypt(
            JSON.stringify(this.profile),
            process.env.VUE_APP_QR4
          ).toString();
          window.localStorage.setItem("sc_c", encryptedText);

          const encryptedStore = this.CryptoJS.AES.encrypt(
            JSON.stringify(data.company),
            process.env.VUE_APP_QR4
          ).toString();
          window.localStorage.setItem("sc_s", encryptedStore);

          window.settings = data.settings.reduce(function (r, a) {
            r[a.tabla] = r[a.tabla] || [];
            r[a.tabla].push(a);
            return r;
          }, Object.create(null));

          this.$store.dispatch("setNavStatus", true);
          this.$store.dispatch("set_menu", true);

          if (this.nextUrl) {
            this.$router.push(this.nextUrl);
          } else {
            this.$router.push("/Welcome");
          }
        } else {
          this.storelst = data;
        }
      });
    },
    popupwindow(url, title, w, h) {
      var left = screen.width / 2 - w / 2;
      var top = screen.height / 2 - h / 2;
      return window.open(
        url,
        title,
        "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
          w +
          ", height=" +
          h +
          ", top=" +
          top +
          ", left=" +
          left
      );
    },
  },
};
//background: url("bg-welcome.jpg") no-repeat center center fixed;
</script>

<style scoped>
.v-text-field__slot input {
  background: white !important;
}

.bglogin {
  background-color: rgba(255, 255, 255, 0.5);
}

.btn-login {
  color: #000000;
}
</style>
